import { ProjectLead as Lead } from "../types";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Eye, MapPin, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import {
  getProjectLeads,
  saveProjectLead,
  setMapState,
  // setSelectedLeadIds,
  setSelectedMarker,
} from "@/features/map-view/mapSlice";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import CurrencyDisplay from "@/components/ui/currency-display";
import { useLoading } from "@/contexts/LoadingContext";
import { useNavigate } from "react-router-dom";
import DialogProjectLeadDetails from "@/components/dialogs/dialog-project-lead-details";
import { formatPrice } from "@/features/kanban/services/KanbanService";
import { Checkbox } from "@/components/ui/checkbox";

function ProjectCard({
  lead,
  className,
  isMarker = false,
  setUpdated,
  showCheckboxes, // Added prop
  onToggleSelect, // Added prop
}: {
  lead: Lead | Partial<Lead>;
  className?: string;
  isMarker?: boolean;
  setUpdated?: () => void;
  showCheckboxes?: boolean; // New prop to control visibility of checkboxes
  onToggleSelect?: (id: number, isSelected: boolean) => void; // New prop for handling checkbox state
}) {
  const dispatch = useDispatch<AppDispatch>();
  const [project, setProject] = useState<Lead | Partial<Lead>>(lead);

  const selectedLeadIds = useSelector(
    (root: RootState) => root.rootReducer.mapViewReducer.selectedLeadIds
  );

  const page = useSelector(
    (root: RootState) => root.rootReducer.mapViewReducer.page
  );
  const limit = useSelector(
    (root: RootState) => root.rootReducer.mapViewReducer.limit
  );

  const [isHovered, setIsHovered] = useState(false);
  const handleCheckboxChange = (checked: boolean) => {
    if (project.id) {
      onToggleSelect?.(project.id, checked); // Call parent handler
    }
  };

  const { setLoading } = useLoading();

  const navigate = useNavigate();

  function setshowPop() {
    dispatch(setSelectedMarker(project));
    changeMapLocation();
  }

  function changeMapLocation() {
    setLoading(true);
    dispatch(
      setMapState({ latitude: project.latitude, longitude: project.longitude })
    );
    setLoading(false);
  }

  useEffect(() => {
    if (!project?.project_image_ids || project?.project_image_ids.length <= 0) {
      const temp = { ...project };
      temp.project_image_ids = [
        {
          id: 0,
          image_url:
            "https://acropolis-wp-content-uploads.s3.us-west-1.amazonaws.com/Building-Types-Hero.webp",
        },
      ];
      setProject(temp);
    }
  }, [project]);

  const saveLead = useCallback(() => {
    if (project.starred) {
      if (project.crm_lead_id) {
        navigate(`/dashboard/leads/${project.crm_lead_id}`);
      }
      return;
    }
    setLoading(true);
    dispatch(saveProjectLead({ leadId: project.id }))
      .finally(() => {
        dispatch(
          getProjectLeads({
            params: {
              page: page,
              limit: limit,
            },
            body: {
              filters: {
                country: ["Canada"],
              },
            },
          })
        );
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        if (setUpdated) setUpdated();
      });
  }, [project, dispatch, setLoading, navigate, setUpdated]);

  // functionality to set flag for projects
  // const setFlags = useCallback(() => {
  //   setLoading(true);
  //   dispatch(
  //     setFlagLead({ projectLeadId: project.id, flagged: !project.flagged })
  //   )
  //     .finally(() => {
  //       dispatch(
  //         getProjectLeads({
  //           params: {
  //             view: "map",
  //           },
  //           body: {},
  //         })
  //       );
  //     })
  //     .finally(() => {
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1000);
  //       if (setUpdated) setUpdated();
  //     });
  // }, [project, dispatch, setLoading]);

  // functionality to set not interested for projects
  // const setNotInterested = useCallback(() => {
  //   setLoading(true);
  //   dispatch(
  //     setFlagLead({
  //       projectLeadId: project.id,
  //       not_interested: !project.not_interested,
  //     })
  //   )
  //     .finally(() => {
  //       dispatch(
  //         getProjectLeads({
  //           params: {
  //             view: "map",
  //           },
  //           body: {},
  //         })
  //       );
  //     })
  //     .finally(() => {
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1000);
  //       if (setUpdated) setUpdated();
  //     });
  // }, [project, dispatch, setLoading]);

  // const setSelectedLeads = (leadIds: number[]) => {
  //   dispatch(setSelectedLeadIds(leadIds));
  // };

  return (
    <Card
      className={`relative overflow-hidden
       ${className}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CardHeader className="p-0 relative cursor-pointer">
        <Carousel
          opts={{
            align: "center",
            loop: true,
          }}
        >
          <CarouselContent>
            {project.project_image_ids?.map((img, ind) => (
              <CarouselItem key={ind} className="relative">
                {/* src={project.image_url */}
                <div className="absolute top-2 left-6 flex items-center gap-2">
                  {/* <Checkbox
                    className="bg-white/80 hover:bg-white w-7 h-7"
                    onCheckedChange={(checked) => {
                      if (checked) {
                        // Add `project.id` to the array
                        if (project.id)
                          setSelectedLeads([...selectedLeadIds, project.id]);
                      } else {
                        // Remove `project.id` from the array
                        setSelectedLeads(
                          selectedLeadIds.filter(
                            (id) => id !== project.id
                          ) as number[]
                        );
                      }
                      // console.log("Selected Leads:", selectedLeads); // Check the updated array
                    }}
                    aria-label="Select project"
                  /> */}
                  {(isHovered || showCheckboxes) && !project?.starred && (
                    <Checkbox
                      className="bg-white/80 !hover:bg-bmpGreen-100 w-7 h-7 size-5"
                      checked={selectedLeadIds.includes(project?.id as number)}
                      onCheckedChange={handleCheckboxChange}
                      aria-label="Select project"
                    />
                  )}
                </div>

                {project.project_type_id &&
                  project.project_type_id.length > 0 && (
                    <Button
                      variant="ghost"
                      size="sm"
                      className="absolute bottom-2 left-6 bg-white/80 hover:bg-white"
                    >
                      {project.project_type_id[1]}
                    </Button>
                  )}

                <img
                  src={img.image_url}
                  alt="project image"
                  className="w-full h-48 object-fill"
                  onClick={setshowPop}
                />
                <div className="flex gap-2 absolute top-2 right-2">
                  {/* <Button
        variant="ghost"
        size="sm"
        className="bg-white/80 hover:bg-white"
        onClick={setFlags}
      >
        <Flag
          className="h-5 w-5"
          fill={project.flagged ? "#1b7531" : "#fff"}
        />
      </Button> */}

                  {project.starred ? (
                    // <Tooltip>
                    //   <TooltipTrigger className="z-1000" asChild>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="bg-white/80 hover:bg-white"
                      onClick={saveLead}
                      title="View in CRM"
                    >
                      <Eye className="h-5 w-5" />
                    </Button>
                  ) : (
                    <Button
                      variant="ghost"
                      size="sm"
                      className="bg-white/80 hover:bg-white"
                      onClick={saveLead}
                      title="Add to CRM"
                    >
                      <Plus className="h-5 w-5" />
                    </Button>
                  )}
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious className="absolute left-2 bg-white/80 hover:bg-white" />
          <CarouselNext className="absolute right-2 bg-white/80 hover:bg-white" />
        </Carousel>
      </CardHeader>
      <CardContent className="p-2 flex flex-col gap-2 text-start h-fit">
        <CardTitle
          onClick={setshowPop}
          className="text-[14px] w-full max-h-[100px] overflow-hidden text-ellipsis leading-tight cursor-pointer line-clamp-2"
        >
          {project.name}
        </CardTitle>
        <p className="text-sm text-gray-600 cursor-pointer">
          <CurrencyDisplay currencyCode={project?.company_currency?.[1]} />
          {project?.budget && <>{formatPrice(project.budget)}</>}
          <span> Permit Issued: {project?.permit_issue_date}</span>
        </p>
        <p className="text-sm flex gap-1 mt-0">
          <MapPin size={16} />
          <span>
            {project.city}, {project?.province_id?.[1]}{" "}
            {project?.country_id?.[1]}{" "}
          </span>
        </p>
      </CardContent>
      <CardFooter className="w-full flex justify-between absolute bottom-0 px-2 py-2 bg-gray-50 text-[13px] text-gray-500">
        {project.builder ? (
          <p
            className="m-0 max-w-[150px] text-start h-[20px] overflow-hidden text-ellipsis"
            title={
              project.builder.company_id ? project.builder.company_id[1] : ""
            }
          >
            {project.builder.company_id ? project.builder.company_id[1] : ""}
          </p>
        ) : (
          <span></span>
        )}
        {Array.isArray(project.lead_project_id) && !isMarker && project.id && (
          // <Link
          //   to={`/dashboard/explore/${project.lead_project_id[0]}?project=true`}
          //   className="text-[11px] text-sm p-0 text-charcoal"
          // >
          //   View project details
          // </Link>
          <DialogProjectLeadDetails id={project.id} />
        )}
      </CardFooter>
    </Card>
  );
}

export default React.memo(ProjectCard);
