/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChevronLeft, ChevronRight, MapPin } from "lucide-react";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { useCallback, useEffect, useState } from "react";
import axiosInstance from "@/api/axios";
import TypographyH1 from "@/components/typography/TypographyH1";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import GradientButton from "@/components/ui/gradient-button";
import { useLoading } from "@/contexts/LoadingContext";
import DetailSkeleton from "./skeleton-details";
import { Button } from "@/components/ui/button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store";
import { Link, useNavigate } from "react-router-dom";
import { saveProjectLead } from "@/features/map-view/mapSlice";
import { TypographyP } from "@/components/typography/TypographyP";
import { formatPrice } from "@/features/kanban/services/KanbanService";
export default function Component({ id }: { id: string }) {
  const [details, setDetails] = useState<any>();
  const dispatch = useDispatch<AppDispatch>();
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const [leadId, setLeadId] = useState(id);

  const leadIds = useSelector(
    (state: RootState) => state.rootReducer.mapViewReducer.lead_ids
  );

  // const [updated, setUpdated] = useState(false);
  // const toggleUpdated = () => {
  //   setUpdated(!updated);
  // };
  // const getProjectLeadDetails = useCallback(async (params: object) => {
  //   // console.log("get project leads", params, data);
  //   try {
  //     setLoading(true);
  //     const response = await axiosInstance({
  //       method: "GET",
  //       url: "/odoo/api/v1/projects",
  //       params: params,
  //     });

  //     console.log("response", response);

  //     return response;
  //   } catch (err) {
  //     console.log("err", err);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, []);

  const getLeadDetails = useCallback(async (params: object) => {
    // console.log("get project leads", params, data);
    try {
      setLoading(true);
      const response = await axiosInstance({
        method: "POST",
        url: "/odoo/api/v1/projectLeads/getDetails",
        data: params,
      });

      console.log("response", response);

      return response;
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    let response;
    const getResponse = async () => {
      response = await getLeadDetails({
        id: +leadId,
        lead_ids: leadIds,
      });
      if (response && response.status == 200) {
        setDetails(response.data.data);
      }
      console.log("details response", response);
    };

    getResponse();
  }, [leadId, getLeadDetails, leadIds]);

  useEffect(() => {
    if (!details?.project_image_ids || details?.project_image_ids.length <= 0) {
      const temp = { ...details };
      temp.project_image_ids = [
        {
          id: 0,
          image_url:
            "https://acropolis-wp-content-uploads.s3.us-west-1.amazonaws.com/Building-Types-Hero.webp",
        },
      ];
      setDetails(temp);
    }
  }, [details]);

  const saveLead = useCallback(() => {
    if (details.starred) {
      if (details.crm_lead_id) {
        navigate(`/dashboard/leads/${details.crm_lead_id}`);
      }
      return;
    }
    setLoading(true);
    dispatch(saveProjectLead({ leadId: Number(id) }));
    setLoading(false);
  }, [details, dispatch, navigate, setLoading, id]);

  return (
    <div className="w-full h-full">
      {details ? (
        <div className="w-full h-full">
          <div className="relative grid grid-cols-2">
            <div>
              <Carousel
                opts={{
                  align: "center",
                  loop: true,
                }}
              >
                <CarouselContent>
                  {details?.project_image_ids &&
                    details.project_image_ids?.map(
                      (img: { id: number; image_url: string }, ind: number) => (
                        <CarouselItem key={ind} className="relative">
                          <img
                            src={
                              img.image_url
                                ? img.image_url
                                : `https://v0.dev/placeholder.svg?height=400&width=800`
                            }
                            alt="Construction site"
                            width={800}
                            height={400}
                            className="w-full h-[400px] object-cover rounded-lg"
                          />
                        </CarouselItem>
                      )
                    )}
                </CarouselContent>
                <CarouselPrevious className="absolute left-2 bg-white/80 hover:bg-white" />
                <CarouselNext className="absolute right-2 bg-white/80 hover:bg-white" />
              </Carousel>
            </div>
            <div className="space-y-4 p-3">
              <div className="w-full pe-4 flex justify-between items-center">
                {/* <div className="flex items-start justify-between"> */}
                <TypographyH1 className="!text-[24px] leading-tight font-bold">
                  {details?.display_name || details?.name}
                </TypographyH1>
                {/* </div> */}
              </div>

              {details?.address && (
                <div className="flex items-center gap-2 text-muted-foreground">
                  <MapPin className="h-4 w-4" />
                  <span>{details?.address}</span>
                </div>
              )}

              <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-2">
                {details?.budget && (
                  <Card>
                    <CardContent className="p-4">
                      <div className="text-sm text-muted-foreground">
                        Cost of Construction
                      </div>
                      <div className="text-md font-bold">
                        {details?.budget
                          ? "$" + formatPrice(details?.budget)
                          : "N/A"}
                      </div>
                    </CardContent>
                  </Card>
                )}

                {details?.permit_issue_date && (
                  <Card>
                    <CardContent className="p-4">
                      <div className="text-sm text-muted-foreground">
                        Permit Issued
                      </div>
                      <div className="text-md font-bold">
                        {details?.permit_issue_date
                          ? details?.permit_issue_date
                          : "N/A"}
                      </div>
                    </CardContent>
                  </Card>
                )}

                {details?.project_class_id?.[1] && (
                  <Card>
                    {details?.project_class_id &&
                      details.project_class_id?.[1] !== "N/A" && (
                        <CardContent className="p-4">
                          <div className="text-sm text-muted-foreground">
                            Category
                          </div>
                          <div className="text-md font-bold">
                            {details.project_class_id?.[1]}
                          </div>
                        </CardContent>
                      )}
                  </Card>
                )}
                {details?.project_status_id?.[0] && (
                  <Card>
                    <CardContent className="p-4">
                      <div className="text-sm text-muted-foreground">Stage</div>
                      <div className="text-md font-bold">
                        {details?.project_status_id &&
                          `${details?.project_status_id?.[0]} :  ${details?.project_status_id?.[1]}`}
                      </div>
                    </CardContent>
                  </Card>
                )}
              </div>

              <div className="w-full flex gap-3">
                <div className="w-[50%]">
                  {details.starred ? (
                    <Link to={`/dashboard/leads/${details.crm_lead_id}`}>
                      <Button className="w-full">Go to CRM</Button>
                    </Link>
                  ) : (
                    <Button className="w-full" onClick={saveLead}>
                      Add to CRM
                    </Button>
                  )}
                </div>
                <div className="w-[50%]">
                  {details?.builder?.email && (
                    <a href={`mailTo:${details?.builder?.email}`}>
                      <GradientButton className="w-full">
                        Contact Builder
                      </GradientButton>
                    </a>
                  )}
                </div>
              </div>
            </div>

            {details.linking_leads && (
              <div className="flex gap-2 items-end absolute top-0 right-5">
                <TypographyP className="text-[14px] text-slate-500">
                  {details.linking_leads.current} /{" "}
                  {details.linking_leads.count}
                </TypographyP>
                <div className="flex gap-1">
                  {/* <Link
                    to={`/dashboard/leads/${details.linking_leads.previousLeadId}`}
                  > */}
                  <Button
                    onClick={() => {
                      setLeadId(details.linking_leads.previousLeadId);
                    }}
                    variant="secondary"
                    size="icon"
                    title="Go to previous lead"
                  >
                    <ChevronLeft />
                  </Button>
                  {/* </Link> */}
                  {/* <Link
                    to={`/dashboard/leads/${details.linking_leads.nextLeadId}`}
                  > */}
                  <Button
                    onClick={() => {
                      setLeadId(details.linking_leads.nextLeadId);
                    }}
                    variant="secondary"
                    size="icon"
                    title="Go to next lead"
                  >
                    <ChevronRight />
                  </Button>
                  {/* </Link> */}
                </div>
              </div>
            )}
          </div>

          {/* Project Info */}
          <div className="space-y-4 mt-3">
            {details.description && (
              <Card>
                <CardHeader className="mt-0 py-2">
                  <h3 className="text-lg text-dark font-semibold leading-normal m-0">
                    Project Description
                  </h3>
                </CardHeader>

                <CardContent className="space-y-4 mt-0">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: details?.description || "N/A",
                    }}
                  ></div>
                </CardContent>
              </Card>
            )}

            {details?.builder && (
              <Card className="mt-0">
                <CardHeader className="mt-0 py-2">
                  <h3 className="text-lg text-dark font-semibold leading-normal m-0">
                    Builder Information
                  </h3>
                </CardHeader>
                <CardContent className="flex justify-between mt-0">
                  {/* Builder Company */}
                  {details?.builder.company_id?.[1] && (
                    <div className="space-y-1">
                      <div className="text-sm text-muted-foreground">
                        Builder Company
                      </div>
                      <div>{details.builder.company_id?.[1]}</div>
                    </div>
                  )}

                  {/* Builder Phone */}
                  {details?.builder.phone && (
                    <div className="space-y-1">
                      <div className="text-sm text-muted-foreground">
                        Builder Phone
                      </div>
                      <div>{details.builder.phone}</div>
                    </div>
                  )}

                  {/* Builder Email */}
                  {details?.builder.email && (
                    <div className="space-y-1">
                      <div className="text-sm text-muted-foreground">
                        Builder Email
                      </div>
                      <div>{details.builder.email}</div>
                    </div>
                  )}
                </CardContent>
              </Card>
            )}

            {details?.project_type_id && (
              <Card className="mt-0">
                <CardHeader className="mt-0 py-2">
                  <h3 className="text-lg text-dark font-semibold leading-normal m-0">
                    Project Details
                  </h3>
                </CardHeader>
                <CardContent className="space-y-4 mt-0">
                  <div className="grid grid-cols-2 gap-4">
                    {/* Owner Company */}
                    {details?.owner_company && (
                      <div className="space-y-1">
                        <div className="text-sm text-muted-foreground">
                          Owner Company
                        </div>
                        <div>{details.owner_company}</div>
                      </div>
                    )}

                    {/* Project Type */}
                    {details?.project_type_id?.[1] && (
                      <div className="space-y-1">
                        <div className="text-sm text-muted-foreground">
                          Project Type
                        </div>
                        <div>{details.project_type_id?.[1]}</div>
                      </div>
                    )}

                    {/* Applicant Company */}
                    {details?.applicant_company && (
                      <div className="space-y-1">
                        <div className="text-sm text-muted-foreground">
                          Applicant Company
                        </div>
                        <div>{details.applicant_company}</div>
                      </div>
                    )}

                    {/* Applicant Name */}
                    {details?.applicant_name && (
                      <div className="space-y-1">
                        <div className="text-sm text-muted-foreground">
                          Applicant Name
                        </div>
                        <div>{details.applicant_name}</div>
                      </div>
                    )}

                    {/* Applicant Phone */}
                    {details?.applicant_phone && (
                      <div className="space-y-1">
                        <div className="text-sm text-muted-foreground">
                          Applicant Phone
                        </div>
                        <div>{details.applicant_phone}</div>
                      </div>
                    )}

                    {/* Applicant Email */}
                    {details?.applicant_email && (
                      <div className="space-y-1">
                        <div className="text-sm text-muted-foreground">
                          Applicant Email
                        </div>
                        <div>{details.applicant_email}</div>
                      </div>
                    )}
                  </div>
                </CardContent>
              </Card>
            )}
          </div>
        </div>
      ) : (
        <DetailSkeleton />
      )}
    </div>
  );
}
