import { useEffect, useRef, useState } from "react";
import SalesOverview from "./ui/sales-overview";
import HeroSection from "./ui/hero-section";
import FeaturesOverview from "./ui/features-overview";
import DiscoverLeadsAndWinContractSection from "./ui/discover-leads-win-contracts";
import HowItWorks from "./ui/how-it-works";
import AIPoweredPlatform from "./ui/ai-powered-platform";
import PriceAndPlans from "./ui/pices-and-plans/price-and-plans";
import TypeWriterEffect from "@/components/animations/typewriter-effect";
import { TypographyP } from "@/components/typography/TypographyP";
import MobileAppSection from "./ui/mobile-app-section";
import TrustedPartners from "./ui/trusted-partners";
import ContactSection from "./ui/contact-page";

function Home() {
  const [salesOverVisible, setSalesOverVisible] = useState(false);
  const salesOverviewRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setSalesOverVisible(true);
          observer.disconnect(); // Stop observing after it becomes visible
        }
      },
      {
        threshold: 0, // Adjust this value as needed
      }
    );

    if (salesOverviewRef.current) {
      observer.observe(salesOverviewRef.current);
    }

    return () => {
      observer.disconnect(); // Cleanup on unmount
    };
  }, []);

  return (
    <main className="flex flex-col gap-20 relative">
      <HeroSection />
      <section className="px-[8%] flex flex-col gap-10 bg-gradient-to-t from-[#eefff2] to-[#fff]">
        {salesOverVisible && <SalesOverview />}

        <section ref={salesOverviewRef}>
          {salesOverVisible && <FeaturesOverview />}
        </section>
      </section>
      <section className="px-[4%]">
        <DiscoverLeadsAndWinContractSection />
      </section>
      <section className="ps-[8%] bg-gradient-to-t from-[#eefff2] to-[#fff]">
        <HowItWorks />
      </section>
      <section className="px-[8%] py-4 flex flex-col items-center gap-20 bg-gradient-to-t from-[#eefff2] to-[#fff]">
        <AIPoweredPlatform />
        <PriceAndPlans />
      </section>

      {/* Updated Testimonial Section */}
      <section className="px-[8%] pt-10 pb-10 overflow-hidden">
        <TypeWriterEffect text="What our customers say " />
        <TypographyP className="text-[20px] font-normal text-lightGray">
          Hear from our satisfied customers and clients.
        </TypographyP>
        <div className="h-[500px] overflow-auto">
          <iframe
          id="testimonialto-wall-of-love-for-testimonial-light"
          src="https://embed-v2.testimonial.to/w/wall-of-love-for-testimonial?theme=light&card=base"
          frameBorder="0"
          scrolling="yes"
          width="100%"
          height="90%"
        ></iframe>
        </div>
      </section>

      <section className="bg-gradient-to-t from-[#eefff2] to-[#fff] px-[8%] pt-10">
        <MobileAppSection />
      </section>
      <section className="px-[8%]">
        <TrustedPartners />
      </section>
      <section className="px-[8%] bg-gradient-to-t from-[#eefff2] to-[#fff]">
        <ContactSection />
      </section>
    </main>
  );
}

export default Home;

