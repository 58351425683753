import SlideAndBounce from "@/components/animations/slide-and-bounce";
import TypeWriterEffect from "@/components/animations/typewriter-effect";
// import { TypographyH2 } from "@/components/typography/TypographyH2";
import { TypographyP } from "@/components/typography/TypographyP";
import { MacbookScroll } from "@/components/ui/macbook-scroll";
import discoverImage from "@/assets/home/discover-and-win.svg";
import logoImage from "@/assets/logo.svg";
import { Link } from "react-router-dom";
import { ContainerScrollDemo } from "./container-scroll-demo";

function DiscoverLeadsAndWinContractSection() {
  return (
    <section className="relative">
      <SlideAndBounce>
        <article className="flex flex-col items-center mb-4">
          <div className="flex">
            <TypeWriterEffect text="Discover leads, Win Contracts" />
          </div>
          <TypographyP className="text-lightGray text-[18px] w-[40rem] mx-auto font-normal">
            Discover the latest projects in your area at the perfect time.
          </TypographyP>
        </article>
      </SlideAndBounce>

      {/* <MacbookScrollDemo /> */}
      {/* <img src={discoverImage} className="mx-auto" alt="discoiver-image" /> */}
      <ContainerScrollDemo />
    </section>
  );
}

export function MacbookScrollDemo() {
  return (
    <div className="overflow-hidden dark:bg-[#0B0B0F] bg-white w-full">
      <MacbookScroll
        title={
          <span>
            This Macbook is built with Tailwindcss. <br /> No kidding.
          </span>
        }
        badge={
          <Link to="www.aeroqube.com">
            <Badge className="size-20 bg-white transform -rotate-12" />
          </Link>
        }
        src={discoverImage}
        showGradient={true}
      />
    </div>
  );
}
// Peerlist logo
const Badge = ({ className }: { className?: string }) => {
  return <img src={logoImage} className={className} alt="" />;
};

export default DiscoverLeadsAndWinContractSection;
