import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import ProjectDetails from "@/features/project-listing/project-details/project-details";
import { ScrollArea, ScrollBar } from "../ui/scroll-area";

const DialogProjectLeadDetails: React.FC<{
  id: string | number;
}> = ({ id }) => {
  const [open, setOpen] = useState(false);
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {/* Trigger for opening the dialog */}
      <DialogTrigger asChild>
        {/* <Button size="sm" className="h-[40px] !bg-charcoal">
          <Plus size={20} />
          
        </Button> */}
        <Button
         size="sm"
          variant="link"
          className="sm:text-[11px] xl:text-[11px] !size-fit p-0 text-charcoal"
        >
          View project details
        </Button>
      </DialogTrigger>

      {/* Dialog Content */}
      <DialogContent
        aria-description="View Project lead details"
        aria-describedby="lead-details-dialog-description"
        className="!max-w-[80vw] max-h-[95vh] full z-[1000] overflow-hidden"
      >
        <DialogHeader className="p-0">
          <DialogTitle hidden className="text-center font-sans mt-[-10px] text-[18px] font-semibold leading-[20px] text-black">
            Lead Details
          </DialogTitle>
          <DialogDescription hidden>
            Project lead details dialog
          </DialogDescription>
        </DialogHeader>

        <ScrollArea className="w-full h-[75%]">
          <ProjectDetails id={id + ""} />
          <ScrollBar />
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

export default DialogProjectLeadDetails;
