import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "@/api/axios";
import { ProjectLead as Lead, Project } from "../project-listing/types";

export type MapStyle = "automatic" | "satellite" | "street" | "outdoor";

export type Marker = Lead[] | null;

const initialMap = {
  mapState: {
    longitude: -119.35,
    latitude: 50.23,
    zoom: 4,
    pitch: 20,
    bearing: 0,
    speed: 1.2,
  },
  mapStyle: "automatic" as MapStyle,
  selectedMarker: null as Marker,
  projects: [] as Array<Project>,
  leads: [] as Array<Lead>,
  lead_ids: [] as number[],
  page: 1,
  nextPage: NaN,
  limit: 20,
  totalCount: NaN,
  selectedLeadIds: [] as number[],
};

export type PARAMS_GET_PROJECT_LEADS = {
  page: number;
  limit: number;
  view: "map" | "list";
  sortBy: string;
  sortOrder: "asc" | "desc";
};

export const getProjectLeads = createAsyncThunk(
  "project_map/getProjectLeads",
  async ({
    params,
    body,
  }: {
    params: Partial<PARAMS_GET_PROJECT_LEADS>;
    body: object;
  }) => {
    console.log("get project leads", params, body);

    if (!params?.page) {
      params.page = initialMap.page;
    }
    if (!params?.limit) {
      params.limit = initialMap.limit;
    }
    if (params?.view) {
      // params.limit = initialMap.limit;
      delete params.view;
    }

    try {
      const response = await axiosInstance({
        method: "POST",
        url: "/odoo/api/v1/projectLeads/fetch/v2",
        params: params,
        data: body,
      });

      console.log("response", response);

      const data = response.data.data as {
        // projects: Project[];
        projectLeads: Lead[];
        lead_ids: number[];
        nextPage: number;
        totalCount: number;
      };

      // leads = leads.map((l) => {
      //   return { ...l, image_url: getImage() };
      // });

      console.log("leads data", data);
      return data;
    } catch (err) {
      console.log("err", err);
    }
  }
);

export const saveProjectLead = createAsyncThunk(
  "project_map/saveProjectLead",
  async (data: object) => {
    // console.log("get project leads", params, data);
    try {
      const response = await axiosInstance({
        method: "POST",
        url: "/odoo/api/v1/projectLeads/save",
        // params: params,
        // data: data,
        data: data,
      });

      console.log("response", response);

      return response;
    } catch (err) {
      console.log("err", err);
    }
  }
);

export const saveFilter = async (data: object) => {
  // console.log("get project leads", params, data);
  try {
    const response = await axiosInstance({
      method: "POST",
      url: "/odoo/api/v1/projectLeads/filters/v2",
      data: data,
    });
    console.log("response", response);
    return response;
  } catch (err) {
    console.log("err", err);
  }
};

export const deleteFilter = async (data: { filterId: number }) => {
  // console.log("get project leads", params, data);
  try {
    const response = await axiosInstance({
      method: "POST",
      url: "/odoo/api/v1/projectLeads/filters/delete",
      data: data,
    });
    console.log("response", response);
    return response;
  } catch (err) {
    console.log("err", err);
  }
};

export const setFlagLead = createAsyncThunk(
  "project_map/removeProjectLead",
  async (data: object) => {
    // console.log("get project leads", params, data);
    try {
      const response = await axiosInstance({
        method: "PUT",
        url: "/odoo/api/v1/projectLeads/flags",
        // params: params,
        // data: data,
        data: data,
      });

      console.log("response", response);

      return response;
    } catch (err) {
      console.log("err", err);
    }
  }
);

const mapSlice = createSlice({
  name: "project_map",
  initialState: initialMap,
  reducers: {
    setMapState: (state, action) => {
      state.mapState = { ...state.mapState, ...action.payload };
    },
    setMapStyle: (state, action) => {
      state.mapStyle = action.payload;
    },
    setSelectedMarker: (state, action) => {
      state.selectedMarker = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setNextPage: (state, action) => {
      state.nextPage = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setSelectedLeadIds: (state, action) => {
      state.selectedLeadIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectLeads.fulfilled, (state, action) => {
      if (action.payload) {
        if (state.page == 1) {
          state.leads = action.payload?.projectLeads as Lead[];
        } else {
          state.leads = state.leads.concat(
            (action.payload?.projectLeads || []) as Lead[]
          );
        }

        state.nextPage = action.payload.nextPage;
        state.totalCount = action.payload.totalCount;

        const p1 = action.payload.projectLeads[0];
        const lat = parseFloat(Number(p1.latitude).toFixed(2));
        const lng = parseFloat(Number(p1.longitude).toFixed(2));
        const cords = { latitude: lat, longitude: lng };
        state.mapState = { ...state.mapState, ...cords };
        if (action.payload?.lead_ids) {
          state.lead_ids = action.payload.lead_ids;
        }
      }
      // }
    });
  },
});

export const {
  setMapState,
  setMapStyle,
  setSelectedMarker,
  setPage,
  setLimit,
  setSelectedLeadIds,
} = mapSlice.actions;
export default mapSlice.reducer;
