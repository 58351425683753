import React from "react";
// import { TypographyH2 } from "@/components/typography/TypographyH2";
import { TypographyP } from "@/components/typography/TypographyP";
import SlideAndBounce from "@/components/animations/slide-and-bounce";
import { TypographyH2 } from "@/components/typography/TypographyH2";
// import TypeWriterEffect from "@/components/animations/typewriter-effect";
import { Cover } from "@/components/ui/cover";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import salesLeadImage from "@/assets/home/smart-lead-recommedation.webp";
import automatedFlowImage from "@/assets/home/automated-workflows.svg";
import salesAssistantImage from "@/assets/home/sales-assistant.svg";

function AIPoweredPlatform() {
  // const descriptionStyle =
  //   "text-lightGray text-[16px] leading-[25.6px] font-medium";

  const aiFeatures = [
    {
      title: "Smart Lead Recommendations",
      description:
        "Our AI analyzes your preferences and sales history to suggest high-potential leads, making it easier for you to focus on the right opportunities.",
      image: salesLeadImage,
    },
    {
      title: "Automated Workflows",
      description:
        "Automate repetitive tasks and streamline your sales process with intelligent workflows that save time and reduce manual effort.",
      image: automatedFlowImage,
    },
    {
      title: "Sales Assistant",
      description:
        "Get sales tips and insights based on real-time data, to help improve your sales process and win more jobs.",
      image: salesAssistantImage,
    },
  ];

  return (
    <section>
      <SlideAndBounce>
        <article className="flex flex-col items-center">
          <div className="flex">
            <TypographyH2 className="text-primary !text-[20px] font-semibold">
              Your Personal Sales Assistant in the Construction Industry
            </TypographyH2>
          </div>
          {/* <TypeWriterEffect text="AI Powered Platform" /> */}
          <Cover className="text-[30px] font-semibold">
            AI Powered Platform
          </Cover>
          <TypographyP className="text-lightGray mt-2 font-normal text-[18px] w-[40rem] mx-auto">
            At BuildMapper, we’ve integrated advanced AI to make sure your
            pipeline is always full. Our system automatically recommends the
            most relevant projects, helps you optimize your workflow, and gives
            you smart tips on improving your close rate. No more guesswork—just
            results.
          </TypographyP>
        </article>
        <article className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
          {aiFeatures.map((feat, ind) => (
            <div key={ind}>
              {/* TODO try aceternity 3D cards */}
              <Card className="sm:w-[280px] w-[380px] border-none h-[550px]  flex flex-col gap-3">
                <CardHeader className="p-0">
                  <img
                    src={feat.image}
                    className="h-full w-[380px] object-contain"
                    alt="sales-lead-image"
                  />
                </CardHeader>
                <CardContent className="p-0">
                  <TypographyP className="text-[20px] font-medium">
                    {feat.title}
                  </TypographyP>
                </CardContent>
                <CardFooter>
                  <TypographyP className="text-[16px] font-normal text-lightGray">
                    {feat.description}
                  </TypographyP>
                </CardFooter>
              </Card>
            </div>
          ))}
        </article>
      </SlideAndBounce>
    </section>
  );
}

export default React.memo(AIPoweredPlatform);
